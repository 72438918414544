
import ajaxReq from './ajaxReq'

export const GetDoctorInfos = data => {
  return ajaxReq('/Manager/GetDoctorInfos', data, 'POST')
}

export const GetDoctorInfosByPage = data => {
  return ajaxReq('/Manager/GetDoctorInfosByPage', data, 'POST')
}

export const GetMeetingInfo = data => {
  return ajaxReq('/Manager/GetMeetingInfo', data, 'GET')
}

export const GetSchedule = data => {
  return ajaxReq('/Manager/GetSchedule', data, 'GET')
}

export const GetScheduleDetail = data => {
  return ajaxReq('/Manager/GetScheduleDetail', data, 'GET')
}

export const GetScheduleDetailById = data => {
  return ajaxReq('/Manager/GetScheduleDetailById', data, 'GET')
}