<template>
  <div>
    <div class="doctorInfo">
      <img class="Portrait" :src="doctorInfo.Img | getPortrait" />
      <div class="doctorText">
        <span class="custom-title">{{ doctorInfo.Name }}</span>
        <div>{{ doctorInfo.Hospital }}</div>
      </div>
    </div>
    <div class="doctorSchedule">
      <van-cell-group v-for="(item, i) in detail" :key="i">
        <van-cell :title="item.Date" :value="detail.length" />
        <van-cell is-link v-for="c in item.Meetings" :key="c.ID">
          <template #title>
            <div>
              <div>{{c.Topic}}</div>
              <div class="textCenter"><van-icon name="clock-o" /> {{c.Time}}</div>
              <div class="textCenter"><van-icon name="wap-home-o" /> {{c.Group}}</div>
              <div class="textCenter"><van-icon name="flag-o" /> {{c.Identype | getIdentype}}</div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { GetMeetingInfo } from "@/api/index.js";

export default {
  name: "DoctorDetail",
  data() {
    return {
      doctorId: this.$route.params.doctorId || "",
      doctorInfo: JSON.parse(sessionStorage.DoctorDetail) || {},
      detail: [],
    };
  },
  methods: {
    async GetMeetingInfoAsync() {
      const r = await GetMeetingInfo({
        doctorId: this.doctorId,
      });
      if (r.code === 1) {
        console.log(r.data);
        this.detail = r.data;
      } else {
      }
    },
  },
  created() {
    this.GetMeetingInfoAsync();
  },
  filters: {
    getPortrait(v) {
      return v || require("@/assets/img-header.jpg");
    },
    getIdentype(v){
      return v === 0 ? "主持": "讲者"
    }
  },
};
</script>

<style scoped>
.doctorInfo {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
}
.doctorText {
  padding-left: 16px;
}
.doctorText > .custom-title {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6;
  color: #2d5abf;
}
.doctorText > div {
  margin: 0;
  line-height: 1.8;
  font-size: 15px;
  color: #999;
}
.Portrait {
  display: block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #eee;
}
.van-icon-arrow {
  margin-top: 35px;
}
.textCenter {
  display: flex;
  align-items: center;
}
.textCenter > .van-icon {
  margin-right: 5px;
}
</style>